import React, { useState, useEffect } from 'react';
import '../assets/Pagination.css'

const ListPagination = (props) => {
    useEffect(() => {
        if (props.cardsDB.length < props.limitUp + 100) {
            setUpDis(true);
        }
    }, []);
    const [upDis, setUpDis] = useState(false);
    const [downDis, setDownDis] = useState(true);
    const onClickNext = () => {
        //If the number of cards is less than the limit, disable the next button
        if (props.cardsDB.length < props.limitUp + 100) {
            setUpDis(true);
        }
        props.setPageIndex(props.pageIndex + 1);
        props.setLimitUp(props.limitUp + 100);
        props.setLimitDown(props.limitDown + 100);
        props.getUp();
        if (props.pageIndex + 1 == 2) { setDownDis(false) }
    }
    const onClickTotalNext = () => {
        let newPageIndex = 0;
        newPageIndex = Math.ceil(props.cardsDB.length / 100);
        props.setPageIndex(newPageIndex)
        props.setLimitUp(newPageIndex * 100)
        props.setLimitDown((newPageIndex * 100) - 100);
        setUpDis(true);
        setDownDis(false);
        props.getUp();
    }
    const onClickPrev = () => {
        //If the number of cards is equal to 0, disable the prev button
        if (props.pageIndex - 1 == 0) {
            setDownDis(true);
            return;
        }
        setUpDis(false);
        props.setPageIndex(props.pageIndex - 1);
        props.setLimitUp(props.limitUp - 100);
        props.setLimitDown(props.limitDown - 100);
        props.getUp();
    }
    const onClickTotalPrev = () => {
        setUpDis(false);
        setDownDis(true);
        props.setPageIndex(1);
        props.setLimitUp(100);
        props.setLimitDown(0);
        props.getUp();
    }
    return (
        <div className='pagination-component'>
            <button
                onClick={onClickTotalPrev}
            >
                <i className="fa fa-arrow-left bg-color-secondary"></i>
            </button>
            <h5 className='pageDes'>{props.limitDown}</h5>
            <button
                onClick={onClickPrev}
                disabled={downDis}
            >Prev</button>
            <h6>Page {props.pageIndex}</h6>
            <button
                onClick={onClickNext}
                disabled={upDis}
            >Next</button>
            <h5 className='pageDes'>{props.limitUp}</h5>
            <button
                onClick={onClickTotalNext}
            >
                <i className="fa fa-arrow-right bg-color-secondary"></i>
            </button>
        </div>
    );
};



export default ListPagination;
