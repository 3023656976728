const api = {
    baseUrl: '/mock_data', //mock data base folder
    host: 'https://mythicportal.com',
    //host: 'http://localhost',
    port: '3001',
    nfts: '/nfts.json',
    nftShowcases: '/nft_showcases.json',
    authors: '/authors.json',
    authorsSales: '/author_ranks.json',
    hotCollections: '/hot-collections.json',
    contactUs: '/contact-forms',
    blogs: '/blog-posts',
    recent: '/blog-posts/recent.json',
    comments: '/blog-posts/comments.json',
    tags: '/blog-posts/tags.json',
}

export default api;