import React, { memo, useEffect, useState } from 'react';
import styled from "styled-components";
import { DataBaseCards } from '../../Utils/CardsDB';

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

//react functional component
const NftMythiCard = ({ nft, className = 'd-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4', height, onImgLoad, setSelling, setTransfer }) => {
    const [img, setImg] = useState(undefined);
    const [name, setName] = useState('');
    const [faction, setFaction] = useState('');
    const [description, setDescription] = useState('');
    const [symbol, setSymbol] = useState('MYTHICARD')
    const hash = String(nft.properties.ipfsHash);
    const newHas = hash.substring(1, hash.length -1)
    let ipfsAddress = `https://${newHas}.ipfs.dweb.link`
    useEffect(() => {
        const gp = JSON.parse(nft.properties.gp);
        const dbp = JSON.parse(nft.properties.dbp);
        setName(gp.n);
        setFaction(gp.f);
        setDescription(gp.d);
        for (let i = 0; i < DataBaseCards.length; i++) {
            if (DataBaseCards[i].dbid == dbp.dbid) {
                setImg(DataBaseCards[i].img);
                break;
            }
        }
    }, []);
    const onSellBtn = () => {
        sessionStorage.setItem('itemSellName', `${name}`)
        sessionStorage.setItem('itemSellID', `${nft._id}`)
        sessionStorage.setItem('itemSellSymbol', `${symbol}`)
        setSelling(true);
    }
    const onTransferBtn = () => {
        sessionStorage.setItem('itemTransName', `${name}`)
        sessionStorage.setItem('itemTransID', `${nft._id}`)
        sessionStorage.setItem('itemTransSymbol', `${symbol}`)
        setTransfer(true);
    }
    const goIpfs = () => {

    }
    return (
        <div className={className}>
            <div className="nft__item m-0">
                <div className="nft__item_wrap" style={{ height: `${height}px` }}>
                    <Outer>
                        <span>
                            <img onLoad={onImgLoad} src={img} className="lazy nft__item_preview" alt="" />
                        </span>
                    </Outer>
                </div>
                <div className="nft__item_info2">
                    <a target="_blank" rel="noopener noreferrer" href={ipfsAddress}>
                        <h4>{name}</h4>
                    </a>
                    <h4 className='type'>{faction} <span className="bold">(nft ID: {nft._id})</span></h4>
                    <div className="nft__description">
                        {description}
                    </div>
                    <button className='btn-nftCard'
                        onClick={() => { onSellBtn() }}
                    >Sell</button>
                    <button className='btn-nftCard'
                        onClick={() => { onTransferBtn() }}
                    >Transfer</button>
                </div>{/**/}
            </div>
        </div>
    );
};

export default memo(NftMythiCard);