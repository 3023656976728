import React, { useEffect, useState } from "react";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import {
  Link,
  useMatch,
  useResolvedPath
} from "react-router-dom";
import useOnclickOutside from "react-cool-onclickoutside";
import { useNavigate } from 'react-router-dom';

setDefaultBreakpoints([
  { xs: 0 },
  { l: 1199 },
  { xl: 1200 }
]);

const NavLink = (props) => {
  let resolved = useResolvedPath(props.to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      {...props}
      className={match ? 'active' : 'non-active'}
    />
  )
};
const Header = function (props, { className }) {
  const navigate = useNavigate();


  const [openMenu, setOpenMenu] = React.useState(false);
  const [isLogged, setIsLogged] = React.useState(false);
  const handleBtnClick = () => {
    setOpenMenu(!openMenu);
  };
  const closeMenu = () => {
    setOpenMenu(false);
  };
  const ref = useOnclickOutside(() => {
    closeMenu();
  });
  const [showmenu, btn_icon] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem('isLogged') === 'yes') {
      setIsLogged(true);
    }
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        totop.classList.add("show");

      } else {
        header.classList.remove("sticky");
        totop.classList.remove("show");
      } if (window.pageYOffset > sticky) {
        closeMenu();
      }
    });

    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  const onLogOut = () => {
    sessionStorage.clear();
    navigate('/');
    window.location.reload();
  }
  return (
    <header className={`navbar white ${className}`} id="myHeader">
      <div className='container'>
        <div className='row w-100-nav'>
          <div className='logo px-0'>
            <div className='navbar-title navbar-item'>
              <NavLink to="/">
                <img
                  src="/img/logo.png"
                  className="img-fluid d-block"
                  alt="#"
                />
                <img
                  src="/img/logo-2.png"
                  className="img-fluid d-3"
                  alt="#"
                />
                <img
                  src="/img/logo-3.png"
                  className="img-fluid d-4"
                  alt="#"
                />
                <img
                  src="/img/logo-light.png"
                  className="img-fluid d-none"
                  alt="#"
                />
              </NavLink>
            </div>
          </div>

          <BreakpointProvider>
            <Breakpoint l down>
              {showmenu &&
                <div className='menu'>
                  <div className='navbar-item'>
                    <div ref={ref}>
                      <div className="dropdown-custom dropdown-toggle btn"
                        onClick={handleBtnClick}
                      >
                        Purchase
                      </div>
                      {openMenu && (
                        <div className='item-dropdown'>
                          <div className="dropdown" onClick={closeMenu}>
                            <a onClick={() => props.setPurchaseModal(true)}>Purchase Packs</a>
                            {/*<a onClick={() => openPurchase('lands')}>Purchase Land</a>*/}
                            <NavLink to="/nftmarket" onClick={() => btn_icon(!showmenu)}>NFT Market</NavLink>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {isLogged && <div className='navbar-item'>
                    <NavLink to="/openpacks">
                      Open Packs
                      <span className='lines'></span>
                    </NavLink>
                  </div>}
                  {isLogged && <div className='navbar-item'>
                    <NavLink to="/inventory">
                      NFT Inventory
                      <span className='lines'></span>
                    </NavLink>
                  </div>}
                  {isLogged && <div className='navbar-item'>
                    <NavLink to="/inventory">
                      {sessionStorage.getItem('userName')}
                      <span className='lines'></span>
                    </NavLink>
                  </div>}
                </div>
              }
            </Breakpoint>
            <Breakpoint xl>
              <div className='menu'>
                <div className='navbar-item'>
                  <div ref={ref}>
                    <div className="dropdown-custom dropdown-toggle btn"
                      onMouseEnter={handleBtnClick} onMouseLeave={closeMenu}>
                      Purchase
                      <span className='lines'></span>
                      {openMenu && (
                        <div className='item-dropdown'>
                          <div className="dropdown" onClick={closeMenu}>
                            <a onClick={() => props.setPurchaseModal(true)}>Purchase Packs</a>
                            {/*<a onClick={() => openPurchase('lands')}>Purchase Land</a>*/}
                            <NavLink to="/nftmarket" onClick={() => btn_icon(!showmenu)}>NFT Market</NavLink>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {isLogged && <div className='navbar-item'>
                  <NavLink to="/openpacks">
                    Open Packs
                    <span className='lines'></span>
                  </NavLink>
                </div>}
                {isLogged && <div className='navbar-item'>
                  <NavLink to="/inventory">
                    NFT Inventory
                    <span className='lines'></span>
                  </NavLink>
                </div>}
                {isLogged && <div className='navbar-item'>
                  <NavLink to="/inventory">
                    {sessionStorage.getItem('userName')}
                    <span className='lines'></span>
                  </NavLink>
                </div>}
              </div>
            </Breakpoint>
          </BreakpointProvider>

          {isLogged && <div className='mainside'>
            <div className='connect-wal'>
              <NavLink to="/" onClick={() => onLogOut()}>Log Out</NavLink>
            </div>
          </div>}
          {!isLogged && <div className='mainside'>
            <div className='connect-wal'>
              <NavLink to="/wallet">Log In</NavLink>
            </div>
          </div>}
        </div>

        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>

      </div>
    </header>
  );
}
export default Header;