import React, { memo, useState, useEffect } from "react";
import CoinDropDown from "../DropDown/CoinDropDown";
import LoadingPanel from "../../Utils/LoadingPanel";
import Axios from "axios";
import api from "../../core/api";
import CoinSellDropDown from "../DropDown/CoinSellDropDown";
import SSC from "@hive-engine/sscjs";

const SellModal = (props) => {
  useEffect(() => {
    setCardName(sessionStorage.getItem('itemSellName'))
    setSymbol(sessionStorage.getItem('itemSellSymbol'))
    setItemID(sessionStorage.getItem('itemSellID'))
  }, []);
  const amt = 3;
  const [coin, setCoin] = useState("SWAP.HIVE");
  const [price, setPrice] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('Reading Prices');
  const [cardName, setCardName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [itemID, setItemID] = useState('');
  const [errorMsg, setErrorMsg] = useState('error');
  const [showError, setShowError] = useState(false);
  const setPricePack = event => {
    if (event.target.value.length > 10) { return; }
    setPrice(event.target.value);
  }
  const onClickClose = () => {
    props.setSelling(false)
  }
  const onClickSell = () => {
    let newPrice = (parseFloat(price)).toFixed(3);
    if (newPrice <= 0 || price == '') {
      setShowError(true);
      setErrorMsg('Error - Price cannot be 0 or negative')
      setTimeout(hideError, 5000);
    }
    else {
      setLoadingMsg('Selling Item');
      setLoading(true);
      //Create the sell order by using keychain
      Sell(newPrice);
    }
  }
  const hideError = () => {
    setShowError(false);
    setErrorMsg('');
  }
  const Sell = (price) => {
    setLoadingMsg(`Selling NFT`);
    setLoading(true);
    let keychain = window.hive_keychain;
    const userName = sessionStorage.getItem('userName');
    let my_json_obj = {
      "contractName": "nftmarket",
      "contractAction": "sell",
      "contractPayload": {
        "symbol": `${symbol}`,
        "nfts": [`${itemID}`],
        "price": `${price}`,
        "priceSymbol": `${coin}`,
        "fee": 500
      }
    }
    keychain.requestCustomJson(userName, "ssc-mainnet-hive", "Active", JSON.stringify(my_json_obj), "Generate a NFT sell order", (response) => {
      if (response.success === true) {
        checkTx(response.result.id);
      }
      else {
        alert('Something is wrong with sell order');
        setLoading(false);
      }
    });
  }
  const checkTx = async(tx) => {
    setLoadingMsg(`Checking Sell TX ${tx}`);
    const ssc = new SSC('https://api.hive-engine.com/rpc');
    try {
			const transaction = await ssc.getTransactionInfo(tx, async (err, result) => {
				if (err) {
					return err;
				}
				if (result) {
					return result;
				}
			})
			if(transaction == null){
        setTimeout(() => {
          checkTx(tx);
        }, 3000);
      }else{
        let jsonLogs = JSON.parse(transaction.logs);
        if (jsonLogs.events == null) {
          alert(`Something went wrong with selling action \nerror: ${jsonLogs.errors[0]}`);
        }
        else {
          setLoading(false);
          window.location.reload();
          alert('Selling Action Done');
        }
      }
		} catch (error) {
			return error.message;
		}
  }
  return (
    <div>
      {loading && <LoadingPanel
        loadingMsg={loadingMsg}
      />}
      <div className='checkout'>
        <div className='maincheckout'>
          <button className='btn-close' onClick={onClickClose}>x</button>
          <div className='heading'>
            <h3>Sell Card</h3>
          </div>
          <p>You are about to put the Mythic Card <span className="bold">{cardName}</span> on sell.</p>
          <div className='detailcheckout mt-4'>
            <div className='listcheckout'>
              <h6>
                What is the price?.
                <span className="color">{`       (pick Hive or HBD)`}</span>
              </h6>
              <input type="number" name="buy_now_qty" id="buy_now_qty" className="form-control"
                value={price}
                onChange={setPricePack}
                placeholder='Enter Price'
              />
            </div>

          </div>
          <CoinSellDropDown setCoin={(coin) => setCoin(coin)} />
          <div className='heading mt-3'>
            <p>Price</p>
            <div className='subtotal'>
              {(price > 0) && parseFloat(price).toFixed(3)}
            </div>
          </div>
          <div className='heading'>
            <p>NFT Name</p>
            <div className='subtotal'>
              {cardName}
            </div>
          </div>
          <div className='heading'>
            <p>NFT Symbol</p>
            <div className='subtotal'>
              {symbol}
            </div>
          </div>
          <div className='heading'>
            <p>NFT ID</p>
            <div className='subtotal'>
              {itemID}
            </div>
          </div>
          {showError && <div className='error-msg'>
            <p>{errorMsg}</p>
          </div>}
          <button onClick={onClickSell} className='btn-main lead mb-5'>Sell</button>
        </div>
      </div>
    </div>
  );
};

export default memo(SellModal);