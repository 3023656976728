import React, { memo, useCallback } from 'react';
import Select from 'react-select';
import { categoriesMarket} from './constants/filters';

const TopMarket = () => {
    const handleCategory = useCallback((option) => {
        const { value } = option;
        sessionStorage.setItem('marketFilter', value);
        window.location.reload();
    }, []);

    const defaultValue = {
        value: null,
        label: 'Select Filter'
    };

    const customStyles = {
        option: (base, state) => ({
            ...base,
            background: "#fff",
            color: "#333",
            borderRadius: state.isFocused ? "0" : 0,
            "&:hover": {
                background: "#eee",
            }
        }),
        menu: base => ({
            ...base,
            borderRadius: 0,
            marginTop: 0
        }),
        menuList: base => ({
            ...base,
            padding: 0
        }),
        control: (base, state) => ({
            ...base,
            padding: 2
        })
    };

    return (
        <div className="items_filter">
            <div className='dropdownSelect one'>
                <Select
                    styles={customStyles}
                    menuContainerStyle={{ 'zIndex': 999 }}
                    options={[defaultValue, ...categoriesMarket]}
                    onChange={handleCategory}
                    placeholder="Filter by..." 
                />
            </div>
        </div>
    );
}

export default memo(TopMarket);