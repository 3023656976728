import React, { memo, useState, useEffect } from "react";
import CoinDropDown from "../DropDown/CoinDropDown";
import LoadingPanel from "../../Utils/LoadingPanel";
import Axios from "axios";
import api from "../../core/api";
import CoinSellDropDown from "../DropDown/CoinSellDropDown";
import SSC from "@hive-engine/sscjs";

const TransferModal = (props) => {
  useEffect(() => {
    setCardName(sessionStorage.getItem('itemTransName'))
    setSymbol(sessionStorage.getItem('itemTransSymbol'))
    setItemID(sessionStorage.getItem('itemTransID'))
  }, []);
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('Reading Prices');
  const [cardName, setCardName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [itemID, setItemID] = useState('');
  const [errorMsg, setErrorMsg] = useState('error');
  const [showError, setShowError] = useState(false);
  const setUser = event => {
    setUsername(event.target.value);
  }
  const onClickClose = () => {
    props.setTransfer(false)
  }
  const onClickTransfer = () => {
    if (username.length < 1) {
      setShowError(true);
      setErrorMsg('Error - Provide a correct Hive Account')
      setTimeout(hideError, 5000);
    } else {
      transfer(username);
    }
  }
  const hideError = () => {
    setShowError(false);
    setErrorMsg('');
  }
  const transfer = (_to) => {
    setLoadingMsg(`Transferring NFT`);
    setLoading(true);
    let keychain = window.hive_keychain;
    const userName = sessionStorage.getItem('userName');
    let my_json_obj = {
      "contractName": "nft",
      "contractAction": "transfer",
      "contractPayload": {
        "to": `${_to}`,
        "nfts": [
          { "symbol": `${symbol}`, "ids": [`${itemID}`] }
        ]
      }
    }
    keychain.requestCustomJson(userName, "ssc-mainnet-hive", "Active", JSON.stringify(my_json_obj), "Transfer NFT", (response) => {
      if (response.success === true) {
        checkTx(response.result.id);
      }
      else {
        alert('Something is wrong with Transfer');
        setLoading(false);
      }
    });
  }
  const checkTx = async (tx) => {
    setLoadingMsg(`Checking Transfer TX ${tx}`);
    const ssc = new SSC('https://api.hive-engine.com/rpc');
    try {
      const transaction = await ssc.getTransactionInfo(tx, async (err, result) => {
        if (err) {
          return err;
        }
        if (result) {
          return result;
        }
      })
      if (transaction == null) {
        setTimeout(() => {
          checkTx(tx);
        }, 3000);
      } else {
        let jsonLogs = JSON.parse(transaction.logs);
        if (jsonLogs.events == null) {
          alert(`Something went wrong with transfer action \nerror: ${jsonLogs.errors[0]}`);
        }
        else {
          setLoading(false);
          window.location.reload();
          alert('Transfer Action Done');
        }
      }
    } catch (error) {
      return error.message;
    }
  }
  return (
    <div>
      {loading && <LoadingPanel
        loadingMsg={loadingMsg}
      />}
      <div className='checkout'>
        <div className='maincheckout'>
          <button className='btn-close' onClick={onClickClose}>x</button>
          <div className='heading'>
            <h3>Transfer Card</h3>
          </div>
          <p>You are about to transfer the Mythic Card <span className="bold">{cardName}</span></p>
          <div className='detailcheckout mt-4'>
            <div className='listcheckout'>
              <h6>
                Transfer to?.
                <span className="color">{`       (HIVE username)`}</span>
              </h6>
              <input type="text" name="buy_now_qty" id="buy_now_qty" className="form-control"
                value={username}
                onChange={setUser}
                placeholder='Enter Username'
                maxLength={40}
              />
            </div>

          </div>
          <div className='heading'>
            <p>NFT Name</p>
            <div className='subtotal'>
              {cardName}
            </div>
          </div>
          <div className='heading'>
            <p>NFT Symbol</p>
            <div className='subtotal'>
              {symbol}
            </div>
          </div>
          <div className='heading'>
            <p>NFT ID</p>
            <div className='subtotal'>
              {itemID}
            </div>
          </div>
          {showError && <div className='error-msg'>
            <p>{errorMsg}</p>
          </div>}
          <button onClick={onClickTransfer} className='btn-main lead mb-5'>Transfer</button>
        </div>
      </div>
    </div>
  );
};

export default memo(TransferModal);