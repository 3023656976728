import React, { useState, useEffect } from 'react';
import Axios from "axios";
import portal from '../../assets/images/Portal/Portal.png';
import portalHover from '../../assets/images/Portal/Portal.png';
import particlesAnim from '../../assets/images/Portal/effectsCrate.png'
import '../../assets/OpenCrateStyle.css'
import crateBox from '../../assets/images/crates/Crate.png';
import cardBack from '../../assets/images/cards/CardBack.png';
import mythicLogo from '../../assets/Logo/logo.png'
import { DataBaseCards } from '../../Utils/CardsDB';
import LoadingPanel from '../../Utils/LoadingPanel';
import api from '../../core/api';
import PurchaseModal from '../components/PurchaseModal';

const OpenCrates = (props) => {
    const [cratesNumber, setCratesNumber] = useState(undefined);
    const [particlesOn, setParticlesOn] = useState(false);
    const [cardsScreen, setCardsScreen] = useState(false);
    const [cardOpenIndex, setCardOpenIndex] = useState(1);
    const [imagePortal, setImagePortal] = useState(portal);
    const [cardsImages, setCardsImages] = useState([]);
    const [draggableItem, setDraggableItem] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('Loading Information');
    const [purchaseModal, setPurchaseModal] = useState(false);
    let drop = false;
    let crateAvailable = false;
    //let draggableElement;
    let imagesArray = [];
    useEffect(() => {
        setDraggableItem(document.querySelector("#crateBox"));
        for (const dropZone of document.querySelectorAll(".drop-zone")) {
            dropZone.addEventListener("dragenter", e => {
                if (!crateAvailable) { return };
                e.preventDefault();
                if (dropZone.id === "crateOpener") {
                    setImagePortal(portalHover);
                }
            });
            dropZone.addEventListener("dragover", e => {
                e.preventDefault();
            });
            dropZone.addEventListener("dragleave", e => {
                if (!crateAvailable) { return };
                dropZone.classList.remove("drop-zone-over");
            });
            dropZone.addEventListener("drop", e => {
                e.preventDefault();
                //const droppedElementId = e.dataTransfer.getData("elementID");
                const droppedElementId = sessionStorage.getItem("elementID");
                const droppedElement = document.getElementById(droppedElementId);
                if (droppedElement != null) {
                    dropZone.appendChild(droppedElement);
                }
                dropZone.classList.remove("drop-zone-over");
                if (dropZone.id != "crateOpener") {
                    window.location.reload();
                    dropZone.classList.remove("crate-holder-zone");
                    drop = true;
                }
                //If the crate drops over open crate section, it opens the open cards function
                else if (dropZone.id === "crateOpener") {
                    onDropCrate();
                }
                else if (dropZone.id === "crateHolder") {
                    dropZone.classList.remove("crate-holder-zone");
                }
            });
        }
        readCrates();
    }, []);

    const readCrates = () => {
        setLoadingMsg(`Crates Reading`);
        setLoading(true);
        sessionStorage.setItem('cratesmode', 'common')
        setCratesNumber(sessionStorage.getItem('packs'));
        if (sessionStorage.getItem('packs') > 0) {
            crateAvailable = true;
        }
        setLoading(false);
    }

    const onStartDrag = (e) => {
        if (!crateAvailable) { return };
        //e.dataTransfer.setData("elementID", draggableElement.id);
        sessionStorage.setItem('elementID', `${draggableItem.id}`)
        const crateHolder = document.querySelector("#crateHolder");
        crateHolder.classList.add("crate-holder-zone");
    }
    const onEndDrag = () => {
        // if (!crateAvailable) {
        //     alert("Not Enough Crates Available");
        //     return;
        // }
        if (drop) { return; }
        const crateHolder = document.querySelector("#crateHolder")
        crateHolder.classList.remove("crate-holder-zone");
    }
    const onDropCrate = () => {
        if (sessionStorage.getItem('packs') > 0) {
            setParticlesOn(true); // Turn on crate particles
            setTimeout(afterOpenCrate, 1000);
        }
        else {
            alert('Not Available Crates');
            window.location.reload();
        }
    }
    const afterOpenCrate = () => {
        setParticlesOn(false);
        setLoadingMsg(`Crates Opening`);
        setLoading(true);
        let jsonBody = {
            user: sessionStorage.getItem('userName'),
            mode: sessionStorage.getItem('cratesmode'),
            token: sessionStorage.getItem('token'),
        }
        Axios.post(`${api.host}:${api.port}/api/v1/hiveService/opencrate2`, jsonBody).then(
            (response) => {
                sessionStorage.setItem('packs', `${response.data.packs}`)
                setCratesNumber(response.data.packs);
                for (let i = 0; i < response.data.cards.length; i++) {
                    for (let j = 0; j < DataBaseCards.length; j++) {
                        if (response.data.cards[i].dbid === DataBaseCards[j].dbid) {
                            imagesArray.push(DataBaseCards[j].img);
                        }
                    }
                }
                setCardsImages(imagesArray);
                setCardsScreen(true);
                setLoading(false);
            }).catch(function (error) {
                alert(error);
            });
    }
    const onClickCard = (e) => {
        e.target.classList.add("rotated");
        setCardOpenIndex(cardOpenIndex + 1);
        if (cardOpenIndex >= 5) {
            const contBtn = document.querySelector("#continueBtn");
            contBtn.classList.remove("disabled");
        }
    }

    const onContinueBtn = () => {
        setLoadingMsg(``);
        setCardsScreen(false);
        setLoading(false);
        window.location.reload();
    }
    const onBuyBtn = () => {
        setPurchaseModal(true);
    }
    return (
        <div>
            {purchaseModal && <PurchaseModal
                setPurchaseModal={purchaseModal => setPurchaseModal(purchaseModal)}
            />}
            {cardsScreen &&
                <div className='cards-modal'>
                    <img src={mythicLogo} className="logo-modal" />
                    <h2 className='text-modal'>Click On Each Card To Discover It</h2>
                    <button id='continueBtn' className='crate-button disabled'
                        onClick={onContinueBtn}
                    >Continue</button>
                    <div className='cards-section-screen'>
                        <div className='row1-cards'>
                            <div className='card-crate' onClick={onClickCard}>
                                <div className='back-card'>
                                    <img src={cardBack}></img>
                                </div>
                                <div className='front-card'>
                                    <img src={cardsImages[0]}></img>
                                </div>
                            </div>
                            <div className='card-crate' onClick={onClickCard}>
                                <div className='back-card'>
                                    <img src={cardBack}></img>
                                </div>
                                <div className='front-card'>
                                    <img src={cardsImages[1]}></img>
                                </div>
                            </div>
                            <div className='card-crate' onClick={onClickCard}>
                                <div className='back-card'>
                                    <img src={cardBack}></img>
                                </div>
                                <div className='front-card'>
                                    <img src={cardsImages[2]}></img>
                                </div>
                            </div>
                        </div>
                        <div className='row2-cards'>
                            <div className='card-crate' onClick={onClickCard}>
                                <div className='back-card'>
                                    <img src={cardBack}></img>
                                </div>
                                <div className='front-card'>
                                    <img src={cardsImages[3]}></img>
                                </div>
                            </div>
                            <div className='card-crate' onClick={onClickCard}>
                                <div className='back-card'>
                                    <img src={cardBack}></img>
                                </div>
                                <div className='front-card'>
                                    <img src={cardsImages[4]}></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {loading && <LoadingPanel
                loadingMsg={loadingMsg}
            />}
            <React.Fragment>
                <h1 className='open-crate-tittle'> Drag Your<br /> Available Packs<br />To The Portal</h1>
                <div className='portal-img'>
                    <div className='drop-zone' id='crateOpener'>
                        {particlesOn ?
                            <>
                                <img src={particlesAnim} id='particles-on'></img>
                                <img src={particlesAnim} />
                            </>
                        :
                        <img src={imagePortal} />}
                    </div>
                </div>
                <div className='crates-picker'>
                    <div className='crates-group-box'>
                        <h2>Packs Available</h2>
                        <div className='crates-group-open' >
                            <div className='crates-text-open available'>
                                <h3>+{cratesNumber}</h3>
                                <button className='plus-btn' onClick={onBuyBtn}>
                                    <h4>Buy</h4>
                                </button>
                            </div>
                            <div className='drop-zone' id='crateHolder'>
                                <div id='crateBox'
                                    onDragStart={onStartDrag}
                                    onDragEnd={onEndDrag}
                                >
                                    <img src={crateBox}></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </div>
    );
}

export default OpenCrates;
