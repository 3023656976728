import i101 from '../assets/CardImg/101.png'
import i102 from '../assets/CardImg/102.png'
import i103 from '../assets/CardImg/103.png'
import i104 from '../assets/CardImg/104.png'
import i105 from '../assets/CardImg/105.png'
import i106 from '../assets/CardImg/106.png'
import i201 from '../assets/CardImg/201.png'
import i202 from '../assets/CardImg/202.png'
import i203 from '../assets/CardImg/203.png'
import i204 from '../assets/CardImg/204.png'
import i205 from '../assets/CardImg/205.png'
import i206 from '../assets/CardImg/206.png'
import i301 from '../assets/CardImg/301.png'
import i302 from '../assets/CardImg/302.png'
import i303 from '../assets/CardImg/303.png'
import i304 from '../assets/CardImg/304.png'
import i305 from '../assets/CardImg/305.png'
import i306 from '../assets/CardImg/306.png'
import i401 from '../assets/CardImg/401.png'
import i402 from '../assets/CardImg/402.png'
import i403 from '../assets/CardImg/403.png'
import i404 from '../assets/CardImg/404.png'
import i405 from '../assets/CardImg/405.png'
import i406 from '../assets/CardImg/406.png'
import i501 from '../assets/CardImg/501.png'
import i502 from '../assets/CardImg/502.png'
import i503 from '../assets/CardImg/503.png'
import i504 from '../assets/CardImg/504.png'
import i505 from '../assets/CardImg/505.png'
import i506 from '../assets/CardImg/506.png'
import i601 from '../assets/CardImg/601.png'
import i602 from '../assets/CardImg/602.png'
import i603 from '../assets/CardImg/603.png'
import i604 from '../assets/CardImg/604.png'
import i605 from '../assets/CardImg/605.png'
import i606 from '../assets/CardImg/606.png'
import i701 from '../assets/CardImg/701.png'
import i702 from '../assets/CardImg/702.png'
import i703 from '../assets/CardImg/703.png'
import i704 from '../assets/CardImg/704.png'
import i705 from '../assets/CardImg/705.png'
import i706 from '../assets/CardImg/706.png'
import i707 from '../assets/CardImg/707.png'

const DataBaseCards = [
    {dbid: 101, img: i101},
    {dbid: 102, img: i102},
    {dbid: 103, img: i103},
    {dbid: 104, img: i104},
    {dbid: 105, img: i105},
    {dbid: 106, img: i106},
    {dbid: 201, img: i201},
    {dbid: 202, img: i202},
    {dbid: 203, img: i203},
    {dbid: 204, img: i204},
    {dbid: 205, img: i205},
    {dbid: 206, img: i206},
    {dbid: 301, img: i301},
    {dbid: 302, img: i302},
    {dbid: 303, img: i303},
    {dbid: 304, img: i304},
    {dbid: 305, img: i305},
    {dbid: 306, img: i306},
    {dbid: 401, img: i401},
    {dbid: 402, img: i402},
    {dbid: 403, img: i403},
    {dbid: 404, img: i404},
    {dbid: 405, img: i405},
    {dbid: 406, img: i406},
    {dbid: 501, img: i501},
    {dbid: 502, img: i502},
    {dbid: 503, img: i503},
    {dbid: 504, img: i504},
    {dbid: 505, img: i505},
    {dbid: 506, img: i506},
    {dbid: 601, img: i601},
    {dbid: 602, img: i602},
    {dbid: 603, img: i603},
    {dbid: 604, img: i604},
    {dbid: 605, img: i605},
    {dbid: 606, img: i606},
    {dbid: 701, img: i701},
    {dbid: 702, img: i702},
    {dbid: 703, img: i703},
    {dbid: 704, img: i704},
    {dbid: 705, img: i705},
    {dbid: 706, img: i706},
    {dbid: 707, img: i707},
]
export {DataBaseCards}
//1. King Arthurian
//101
// Legendary King Arthur
// An all around high caliber knight

//102
// Legendary Castle
// A Royal Defense

//103
// Rare Mordred
// Betraying his own father, that's morbid

//104
// Uncommon Merlin
// A wizard at magical matters

//105
// Common Guineverre
// Many a war come from a queens
// wandering eyes

//106
// Common Wounded Knight
// To scale the enemy's walls
// they save their last breathe

// 2. Dragon

//201
// Legendary Dragon Rider
// The wrong move leaves you burnt

//202
// Legendary Dragon Nest
// The hottest hangout for dragons

//203
// Rare Black Dragon
// Even his heart is black

//204
// Uncommon Blue Dragon
// Lightning victims are mistaken for the unlucky
// caught in storms

//205
// Common Frost Dragon
// Smitten or Frostbitten

//206
// Common Wounded Knight
// To scale the enemy's walls
// they save their last breathe

// 3. Egyptian

//301
// Legendary Amon-Ra
// The Sun King, always looks on the bright side

//302
// Legendary Pyramid
// Try building a better tomb

//303
// Rare Seth
// Guilty of a sandstorm to bring chaos to your day

//304
// Uncommon Cleopatra
// Empires and leaders all in a day's work

//305
// Common Anubis
// The day you see him it's your last

//306
// Common Wounded Knight
// To scale the enemy's walls
// they save their last breathe

// 4. Greek

//401
// Legendary Zeus
// Reigning king on Mount Olympus

//402
// Legendary Parthenon
// Perched on the acropolis dedicated to Athena

//403
// Rare Prometheus
// Zeus is hot after he stole fire for humans

//404
// Uncommon Aphrodite
// Seduction was her game

//405
// Common Perseus
// Not a fan of women with snake hair

//406
// Common Wounded Knight
// To scale the enemy's walls
// they save their last breathe

//5. Viking
//501
// Legendary Odin
// He has an eye out for cosmic knowledge

//502
// Legendary Viking Ships Camp
// Time to go raiding

//503
// Rare Loki
// His shifty personality is always playing tricks

//504
// Uncommon Freya
// Lovely and rides a chariot pulled by cats... meow

//505
// Common Thor
// He'll hammer out a storm

//506
// Common Wounded Knight
// To scale the enemy's walls
// they save their last breathe

// 6. Undead

//601
// Legendary Skeleton King
// Not in very lively spirits

//602
// Legendary Haunted Village
// Spookyville

//603
// Rare Ghost
// Life the second time around

//604
// Uncommon Zombie
// Whodoo the Voodoo

//605
// Common Skeleton
// He's got a bone to pick with you

//606
// Common Wounded Knight
// To scale the enemy's walls
// they save their last breathe