import React, { useState } from 'react';
import { Routes, Route, Navigate} from "react-router-dom";
import ScrollToTopBtn from './menu/ScrollToTop';
import Header from './menu/header';
import Home2 from './pages/home2';
import Explore from './pages/explore';
import Wallet from './pages/wallet';
//import CreateNft from './pages/Create/index.js';

import { createGlobalStyle } from 'styled-components';
import NftMarket from './pages/NftMarket';
import OpenCrates from './pages/OpenCrates';
import PurchaseModal from './components/PurchaseModal';

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;
const App = () => {
  const [purchaseModal, setPurchaseModal] = useState(false);
  return (
    <div className="wraper">
      <GlobalStyles />
      <Header
        setPurchaseModal={purchaseModal => setPurchaseModal(purchaseModal)}
      />
      <Routes>
        <Route path="*" element={<Navigate to="/home" replace />} />
        <Route path="/home" element={<Home2 />} />
        <Route element={<Home2 />} path="/home" />
        <Route element={<NftMarket />} path="/nftmarket" />
        <Route element={<OpenCrates />} path="/openpacks" />
        <Route element={<Explore />} path="/inventory" />
        <Route element={<Wallet />} path="/wallet" />
      </Routes>
      <ScrollToTopBtn />
      {purchaseModal && <PurchaseModal
        setPurchaseModal={purchaseModal => setPurchaseModal(purchaseModal)}
      />}
    </div>
  );
}
export default App;