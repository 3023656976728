import React, {useEffect, useState} from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import Axios from "axios";
import SSC from "@hive-engine/sscjs";

import {
  Link,
  useMatch,
  useResolvedPath
} from "react-router-dom";
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const NavLink = (props) => {
  let resolved = useResolvedPath(props.to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      {...props}
      className={match ? 'active' : 'non-active'}
    />
  )
};
const Slidermainzero = (props) => {
  const [total, setTotal] = useState(0);
  const [sold , setSold] = useState(0);
  useEffect(() => {
    cratesCounter();
  }, []);
  const cratesCounter = async () => {
    const ssc = new SSC('https://api.hive-engine.com/rpc');
    try {
      const cratesNumber = await ssc.find('nft', 'nfts', { symbol: 'MYTHICARD' }, 1000, 0, [], (err, result) => {
				if (err) {
					return err;
				}
				if (result) {
					return result;
				}
			});
      setTotal(cratesNumber[0].maxSupply/5);
      setSold(Math.ceil(cratesNumber[0].supply/5));
    } catch (error) {
      console.log(error.message)
    }
  }
return (
  <div className="container">
    <div className="row align-items-center">
      <div className="col-md-6 m-auto">
        <div className="spacer-single"></div>
        <div className="spacer-double"></div>
        <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600}>
          <h1 className="text-center">Mythic Portal Marketplace</h1>
        </Reveal>
        <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600}>
          <p className="lead text-center">
            The most innovative cards game enviroment, with the latest trends in play to learn games.
          </p>
        </Reveal>
        <div className="spacer-10"></div>
        <Reveal className='onStep' keyframes={fadeInUp} delay={800} duration={600}>
          <div className='d-flex justify-content-center'>
          <span onClick={() => props.setPurchaseModal(true)} className="btn-main inline lead">Buy Packs</span>
          <NavLink to="/nftmarket"> <span className="btn-main inline white lead mx-0">Visit NFT Market</span></NavLink> 
          </div>
          <div className="spacer-single"></div>
          <div className="spacer-double"></div>
        </Reveal>
        <div className="spacer-10"></div>
        <Reveal className='onStep' keyframes={fadeInUp} delay={1200} duration={1200}>
          <p className="lead text-center counter-intro">
            {total} Packs In Total
          </p>
          <p className="lead text-center counter-introSold">
            {sold} Packs Sold
          </p>
        </Reveal>
      </div>
    </div>
  </div>
)};

export default Slidermainzero;