import React from "react";


class CoinSellDropDown extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: 'hive' };

        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event) {
        this.setState({ value: event.target.value });
        this.props.setCoin(event.target.value);
    }
    render() {
        return (
            <form className="drop-down-purchase">
                <h4>Preferred coin :</h4>
                <select value={this.state.value} onChange={this.handleChange}>
                    <option value="SWAP.HIVE">swap.HIVE</option>
                    <option value="SWAP.HBD">swap.HBD</option>
                </select>
            </form>
        );
    }
}
export default CoinSellDropDown;