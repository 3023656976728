import React, { memo, useState, useEffect } from "react";
import CoinDropDown from "../DropDown/CoinDropDown";
import LoadingPanel from "../../Utils/LoadingPanel";
import Axios from "axios";
import api from "../../core/api";

const PurchaseModal = (props) => {
  useEffect(() => {
    readExc();
  }, []);
  const amt = 2.7;
  const [coin, setCoin] = useState("hive");
  const [qty, setQty] = useState('1');
  const [hive, setHive] = useState(0);
  const [hbd, setHbd] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('Reading Prices');
  const setPricePack = event => {
    const result = event.target.value.replace(/\D/g, '');
    if (result > 10000) {
      setQty(10000)
    } else {
      setQty(result);
    }
  }
  const onClickClose = () => {
    props.setPurchaseModal(false)
  }
  const onClickPurchase = () => {
    setLoadingMsg('Reading Info');
    setLoading(true);
    if (coin == 'hive') {
      Axios.get('https://api.coingecko.com/api/v3/simple/price?ids=HIVE&vs_currencies=USD')
        .then((response) => {
          let valueCoin = 1 / response.data.hive.usd;
          payKeyChain(valueCoin * amt * qty);
        })
        .catch(function (error) {
          alert(error);
        });
    } else if (coin == 'hbd') {
      Axios.get('https://api.coingecko.com/api/v3/simple/price?ids=hive_dollar&vs_currencies=USD')
        .then((response) => {
          let valueCoin = 1 / response.data.hive_dollar.usd;
          payKeyChain(valueCoin * amt * qty);
        })
        .catch(function (error) {
          alert(error);
        });
    }
  }
  const readExc = async () => {
    setLoading(true);
    Axios.get('https://api.coingecko.com/api/v3/simple/price?ids=HIVE&vs_currencies=USD')
      .then((response) => {
        let valueCoin = 1 / response.data.hive.usd;
        setHive(valueCoin);
        Axios.get('https://api.coingecko.com/api/v3/simple/price?ids=hive_dollar&vs_currencies=USD')
          .then((response) => {
            let valueCoin = 1 / response.data.hive_dollar.usd;
            setHbd(valueCoin);
            setLoading(false);
          })
          .catch(function (error) {
            alert(error);
          });
      })
      .catch(function (error) {
        alert(error);
      });
  }
  const payKeyChain = (input) => {
    let keychain = window.hive_keychain;
    if (sessionStorage.getItem("isLogged") === "yes") {
        setLoadingMsg("Purchasing Crates");
        if (coin === "hive") {
          keychain.requestTransfer(
            sessionStorage.getItem("userName"),
            "mythicportalcom",
            `${input.toFixed(3)}`,
            //"0.001",
            `Purchase ${qty} Packs`,
            "HIVE",
            (response) => {
              if (response.success === false) {
                alert("Operation Was Not Successful");
                setLoading(false);
              } else if (response.success === true) {
                let jsonBody = {
                  username: response.data.username,
                  txid: response.result.id,
                  symbol: 'MYTHICARD',
                  token: sessionStorage.getItem('token'),
                }
                Axios.post(
                  `${api.host}:${api.port}/api/v1/hiveService/issueCrates`, jsonBody).then((response) => {
                    sessionStorage.setItem('packs', `${response.data.packs}`)
                    setLoading(false);
                    props.setPurchaseModal(false);
                    alert("Operation Completed, go to Open Packs section");
                    window.location.reload();
                  })
                  .catch(function (error) {
                    alert(error + " please reload the page");
                  });
              }
            },
            true
          );
        } else if (coin === "hbd") {
          setLoading(true);
          keychain.requestTransfer(
            sessionStorage.getItem("userName"),
            "mythicportalcom",
            `${input.toFixed(3)}`,
            `Purchase ${qty} Packs`,
            "HBD",
            (response) => {
              if (response.success === false) {
                alert("Operation Was Not Successful");
                setLoading(false);
              } else if (response.success === true) {
                let jsonBody = {
                  username: response.data.username,
                  txid: response.result.id,
                  symbol: 'MYTHICARD',
                  token: sessionStorage.getItem('token'),
                }
                Axios.post(
                  `${api.host}:${api.port}/api/v1/hiveService/issueCrates`, jsonBody).then((response) => {
                    sessionStorage.setItem('packs', `${response.data.packs}`)
                    setLoading(false);
                    props.setPurchaseModal(false);
                    alert("Operation Completed, go to Open Packs section");
                    window.location.reload();
                  })
                  .catch(function (error) {
                    alert(error + " please reload the page");
                  });
              }
            },
            true
          );
        }
       else {
        alert("Log In First");
        window.location.reload();
      }
    }
  }
  return (
    <div>
      {loading && <LoadingPanel
        loadingMsg={loadingMsg}
      />}
      <div className='checkout'>
        <div className='maincheckout'>
          <button className='btn-close' onClick={onClickClose}>x</button>
          <div className='heading'>
            <h3>Purchase Packs</h3>
          </div>
          <p>You are about to purchase <span className="bold">Mythic Portal Packs</span></p>
          <div className='detailcheckout mt-4'>
            <div className='listcheckout'>
              <h6>
                How Many Packs?.
                <span className="color">{`       (${amt} USD each)`}</span>
              </h6>
              <input type="text" name="buy_now_qty" id="buy_now_qty" className="form-control"
                value={qty}
                onChange={setPricePack}
                placeholder='Enter Amount'
              />
            </div>

          </div>
          <CoinDropDown setCoin={(coin) => setCoin(coin)} />
          <div className='heading mt-3'>
            <p>Hive</p>
            <div className='subtotal'>
              {(qty * hive * amt).toFixed(2)}
            </div>
          </div>
          <div className='heading'>
            <p>HBD</p>
            <div className='subtotal'>
              {(qty * hbd * amt).toFixed(2)}
            </div>
          </div>
          <div className='heading'>
            <p>Total USD</p>
            <div className='subtotal'>
              {(qty * amt).toFixed(2)}
            </div>
          </div>
          <button onClick={onClickPurchase} className='btn-main lead mb-5'>Purchase</button>
        </div>
      </div>
    </div>
  );
};

export default memo(PurchaseModal);