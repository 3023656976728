import React, { useEffect, useState } from "react";
import SliderMainZero from '../components/SliderMainZero';
import ColumnNewRedux from '../components/ColumnNewRedux';
import Footer from '../components/footer';
import UserInfo from "../Tools/UserInfo";
import LoadingPanel from "../../Utils/LoadingPanel";
import PurchaseModal from "../components/PurchaseModal";

const Hometwo = () => {
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('Loading Information');
  const [purchaseModal, setPurchaseModal] = useState(false);
  useEffect(() => {
  }, [UserInfo.isLogged]);


  return (
    <div>
      {loading && <LoadingPanel
        loadingMsg={loadingMsg}
      />}
      <section className="jumbotron no-bg bg-gray">
        <SliderMainZero
          setPurchaseModal={purchaseModal => setPurchaseModal(purchaseModal)}
        />
      </section>
      <section className='container-fluid bg-gray'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='text-center'>
                <h2>Popular Items</h2>
                <div className="small-border"></div>
              </div>
            </div>
          </div>
          <ColumnNewRedux />
        </div>
      </section>
      <Footer />
      {purchaseModal && <PurchaseModal
        setPurchaseModal={purchaseModal => setPurchaseModal(purchaseModal)}
      />}
    </div>
  )
};
export default Hometwo;