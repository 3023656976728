import React, { memo, useEffect, useState } from 'react';
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { DataBaseCards } from '../../Utils/CardsDB';

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

//react functional component
const NftCard = ({ nft, className = 'd-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4', height, onImgLoad }) => {
    const [img, setImg] = useState(undefined);
    useEffect(() => {
        for (let i = 0; i < DataBaseCards.length; i++) {
            if (DataBaseCards[i].dbid == nft.dbp.dbid) {
                setImg(DataBaseCards[i].img);
                break;
            }
        }
    }, []);
    const navigate = useNavigate();
    const navigateTo = (link) => {
        navigate(link);
    }
    return (
        <div className={className}>
            <div className="nft__item m-0">
                <div className="nft__item_wrap" style={{ height: `${height}px` }}>
                    <Outer>
                        <span>
                            <img onLoad={onImgLoad} src={img} className="lazy nft__item_preview" alt="" />
                        </span>
                    </Outer>
                </div>
                <div className="nft__item_info2">
                    <span onClick={() => navigateTo(`${nft.nft_link}/${nft.id}`)}>
                        <h4>{nft.gp.n}</h4>
                    </span>
                    <h4 className='type'>{nft.gp.f}</h4>
                    <div className="nft__description">
                        {nft.gp.d}
                    </div>
                </div>{/**/}
            </div>
        </div>
    );
};

export default memo(NftCard);