import React, { useState, useEffect } from 'react';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import MyInventoryCards from '../components/MyInventoryCards';
import LoadingPanel from '../../Utils/LoadingPanel';
import ListPagination from '../../Utils/ListPagination';
import TopInventory from '../components/TopInventory';
import SellModal from '../components/SellModal';
import TransferModal from '../components/TransferModal';
import Topmarket from '../components/Topmarket';
import MarketCards from '../components/MarketCards';
import PurchaseCardModal from '../components/PurchaseCardModal';

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const NftMarket = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [loading, setLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('Loading Cards');
    const [cardsDB, setCardsDB] = useState([]);
    const [purchase, setPurchase] = useState(false);
    const [transfer, setTransfer] = useState(false);
    //PAGINATION
    const [pageIndex, setPageIndex] = useState(1);
    const [limitDown, setLimitDown] = useState(0);
    const [limitUp, setLimitUp] = useState(100);
    const [viewIndex, setViewIndex] = useState(0);
    const getUp = () => {
        window.scrollTo(0, 0);
    }
    return (
        <div>
            {loading && <LoadingPanel
                loadingMsg={loadingMsg}
            />}
            <GlobalStyles />
            <section className='jumbotron breadcumb no-bg' style={{ backgroundImage: `url(${'./img/background/subheader.jpg'})` }}>
                <div className='mainbreadcumb'>
                    <div className='container'>
                        <div className='row m-10-hor'>
                            <div className='col-12'>
                                <h1 className='text-center'>Mythic Cards Market</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='container'>
                <div className='text-inv'><h4>{`${cardsDB.length} Mythic Cards On Sale`}</h4></div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <Topmarket />
                    </div>
                </div>
                <MarketCards
                    setCardsDB={cardsDB => setCardsDB(cardsDB)}
                    limitUp={limitUp}
                    limitDown={limitDown}
                    setLoading={loading => setLoading(loading)}
                    setLoadingMsg={loadingMsg => setLoadingMsg(loadingMsg)}
                    setPurchase={purchase => setPurchase(purchase)}
                    setTransfer={transfer => setTransfer(transfer)}
                />
                <ListPagination
                    setPageIndex={pageIndex => setPageIndex(pageIndex)}
                    pageIndex={pageIndex}
                    setLimitDown={limitDown => setLimitDown(limitDown)}
                    limitDown={limitDown}
                    setLimitUp={limitUp => setLimitUp(limitUp)}
                    limitUp={limitUp}
                    getUp={getUp}
                    cardsDB={cardsDB}
                />
            </section>
            {purchase && <PurchaseCardModal
                setPurchase={purchase => setPurchase(purchase)}
            />}
            {transfer && <TransferModal
                setTransfer={transfer => setTransfer(transfer)}
            />}
            <Footer />
        </div>
    )
}

export default NftMarket;
