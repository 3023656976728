import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../core/api';
import UserInfo from '../Tools/UserInfo';
import Axios from "axios";
import LoadingPanel from '../../Utils/LoadingPanel';


const Wallet = () => {
    let navigate = useNavigate();
    const [keyUser, setKeyUser] = useState(undefined);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('Loading Information');
    const valueUsername = (event) => {
        setKeyUser(event.target.value);
    }
    const logIn = () => {
        setLoading(true);
        setLoadingMsg('Verifying Account');
        let keychain = window.hive_keychain;
        const jsonBody = {
            "username": `${keyUser}`
        }
        let memo;
        Axios.post(`${api.host}:${api.port}/api/v1/authService/memo`, jsonBody).then((response) => {
            memo = response.data.encoded_message;
            keychain.requestVerifyKey(`${keyUser}`, memo, "Posting", (response) => {
                if (response.success === true) {
                    callLogin(`${keyUser}`, response.result);
                }
            });
        }).catch(function (error) {
            sessionStorage.clear();
            alert(error.message);
        });
    }
    const callLogin = (username, encryptedUsername) => {
        const jsonBody = {
            username: username,
            encryptedUsername: encryptedUsername
        }
        Axios.post(`${api.host}:${api.port}/api/v1/authService/login`, jsonBody).then((response) => {
            sessionStorage.clear();
            sessionStorage.setItem('packs', `${response.data.session.packs}`)
            sessionStorage.setItem('userName', `${response.data.session.username}`)
            sessionStorage.setItem('loginType', 'keychain');
            sessionStorage.setItem('token', `${response.data.session.token}`);
            sessionStorage.setItem('isLogged', 'yes');
            navigate('/');
            setLoading(false);
            window.location.reload();
        }).catch(function (error) {
            sessionStorage.clear();
            setError(true);
            alert(error);
        });
    }
    return (
        <div className="walletGroup">
            {loading && <LoadingPanel
                loadingMsg={loadingMsg}
            />}
            <div className="col-lg-3 mb30">
                <span className="box-url">
                    <img src="./img/wallet/1.png" alt="" className="mb20" />
                    <h4>Keychain</h4>
                    <p>Type Valid Hive Account To Login:</p>
                    <div className="input-window">
                        <input id="transferTo" type="text" name="value"
                            onChange={valueUsername}
                            placeholder='Hive Account'
                        />
                    </div>
                    <a className="btn-main inline lead" onClick={() => logIn()}>Login</a>
                </span>
            </div>
        </div>
    )
};
export default Wallet;