import React, { memo, useState, useEffect } from "react";
import LoadingPanel from "../../Utils/LoadingPanel";
import SSC from "@hive-engine/sscjs";

const PurchaseCardModal = (props) => {
  useEffect(() => {
    setSellerName(sessionStorage.getItem('itemSellerName'))
    setPriceSymbol(sessionStorage.getItem('itemBuySymbol'))
    setItemID(sessionStorage.getItem('itemBuyID'))
    setPrice(sessionStorage.getItem('itemBuyPrice'))
    setItemName(sessionStorage.getItem('itemBuyName'))
    setNftSymbol(sessionStorage.getItem('itemBuyNftSymbol'))
  }, []);
  const amt = 3;
  const [coin, setCoin] = useState("SWAP.HIVE");
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('Reading Prices');
  const [sellerName, setSellerName] = useState('');
  const [itemName, setItemName] = useState('');
  const [priceSymbol, setPriceSymbol] = useState('');
  const [nftSymbol, setNftSymbol] = useState('');
  const [itemID, setItemID] = useState('');
  const [errorMsg, setErrorMsg] = useState('error');
  const [showError, setShowError] = useState(false);
  const onClickClose = () => {
    props.setPurchase(false)
  }
  const hideError = () => {
    setShowError(false);
    setErrorMsg('');
  }
  const onClickBuy = () => {
    setLoadingMsg(`Purchasing NFT`);
    setLoading(true);
    let keychain = window.hive_keychain;
    const userName = sessionStorage.getItem('userName');
    let my_json_obj = {
      "contractName": "nftmarket",
      "contractAction": "buy",
      "contractPayload": {
        "symbol": `${nftSymbol}`,
        "nfts": [`${itemID}`],
        "marketAccount": 'mythicportalcom',
        "price": `${price}`,
        "priceSymbol": `${priceSymbol}`
      }
    }
    keychain.requestCustomJson(userName, "ssc-mainnet-hive", "Active", JSON.stringify(my_json_obj), "Purchase NFT", (response) => {
      if (response.success === true) {
        checkTx(response.result.id);
      }
      else {
        alert('Something is wrong with purchase');
        setLoading(false);
      }
    });
  }
  const checkTx = async (tx) => {
    setLoadingMsg(`Checking Sell TX ${tx}`);
    const ssc = new SSC('https://api.hive-engine.com/rpc');
    try {
      const transaction = await ssc.getTransactionInfo(tx, async (err, result) => {
        if (err) {
          return err;
        }
        if (result) {
          return result;
        }
      })
      if (transaction == null) {
        setTimeout(() => {
          checkTx(tx);
        }, 3000);
      } else {
        let jsonLogs = JSON.parse(transaction.logs);
        if (jsonLogs.events == null) {
          alert(`Something went wrong with selling action \nerror: ${jsonLogs.errors[0]}`);
        }
        else {
          setLoading(false);
          window.location.reload();
          alert('Purchase Action Done');
        }
      }
    } catch (error) {
      return error.message;
    }
  }
  return (
    <div>
      {loading && <LoadingPanel
        loadingMsg={loadingMsg}
      />}
      <div className='checkout'>
        <div className='maincheckout'>
          <button className='btn-close' onClick={onClickClose}>x</button>
          <div className='heading'>
            <h3>Buy Card</h3>
          </div>
          <p>You are about to purchase the next Mythic Card:</p>
          <div className='heading mt-3'>
            <p>Price</p>
            <div className='subtotal'>
              {parseFloat(price).toFixed(3)}
            </div>
          </div>
          <div className='heading'>
            <p>Transaction Token</p>
            <div className='subtotal'>
              {priceSymbol}
            </div>
          </div>
          <div className='heading'>
            <p>NFT Name</p>
            <div className='subtotal'>
              {itemName}
            </div>
          </div>
          <div className='heading'>
            <p>Seller Name</p>
            <div className='subtotal'>
              {sellerName}
            </div>
          </div>
          <div className='heading'>
            <p>NFT ID</p>
            <div className='subtotal'>
              {itemID}
            </div>
          </div>
          <div className='heading'>
            <p>NFT Symbol</p>
            <div className='subtotal'>
              {nftSymbol}
            </div>
          </div>
          {showError && <div className='error-msg'>
            <p>{errorMsg}</p>
          </div>}
          <button onClick={onClickBuy} className='btn-main lead mb-5'>Buy</button>
        </div>
      </div>
    </div>
  );
};

export default memo(PurchaseCardModal);