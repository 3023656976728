import React, {useState, useEffect} from 'react';
import './LoadingPanel.css'
import Logo from '../assets/Logo/logo.png'
const LoadingPanel = (props) => {
    useEffect(() => {
        setLoadingMsg(props.loadingMsg);
      }, []);
    const [loadingMsg, setLoadingMsg] = useState('');
    return (
        <div className="loading-screen">
            <div className="loading-logo"><img src={Logo}></img></div>
            <div className="loading-description"><p>{loadingMsg}</p></div>
            <div className="loading-tittle"><h1>Loading...</h1></div>
            <div className="loader-section">
                <div className="lds-hourglass"></div>
            </div>
        </div>
    );
}

export default LoadingPanel;
