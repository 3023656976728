import React, { memo, useEffect, useState } from 'react';
import NftCard from './NftCard';
import Axios from "axios";
import api from '../../core/api';

//react functional component
const ColumnNewRedux = ({ showLoadMore = true, shuffle = false, authorId = null }) => {
    const [height, setHeight] = useState(0);
    const [cardsToShow, setCardsToShow] = useState(undefined);
    const [showCards, setShowCards] = useState(false);
    useEffect(() => {
        ShowCards();
    }, []);
    const ShowCards = async () => {
        const jsonBody = {
            'user': 'user',
            'token': 'token',
        }
        try {
            Axios.post(`${api.host}:${api.port}/api/v1/hiveService/getCards`, jsonBody).then((response) => {
                setCardsToShow(response.data);
                setShowCards(true);
            }).catch(function (e) {
                alert('Netwwork');
            });
        } catch (e) {
            console.log(e)
        }
    }
    // const dispatch = useDispatch();
    // const nftItems = useSelector(selectors.nftItems);
    // const nfts = nftItems ? shuffle ? shuffleArray(nftItems) : nftItems : [];

    const onImgLoad = ({ target: img }) => {
        let currentHeight = height;
        if (currentHeight < img.offsetHeight) {
            setHeight(img.offsetHeight);
        }
    }

    // useEffect(() => {
    //     dispatch(actions.fetchNftsBreakdown(authorId));
    // }, [dispatch, authorId]);

    // //will run when component unmounted
    // useEffect(() => {
    //     return () => {
    //         dispatch(clearFilter());
    //         dispatch(clearNfts());
    //     }
    // },[dispatch]);

    // const loadMore = () => {
    //     dispatch(actions.fetchNftsBreakdown(authorId));
    // }

    return (
        <div className='row'>
            {showCards && cardsToShow.map((nft, index) => (
                <NftCard nft={nft} key={index} onImgLoad={onImgLoad} height={height} />
            ))}
        </div>
    );
};

export default memo(ColumnNewRedux);