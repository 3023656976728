export const categories = [
    {
        value: 'rare',
        label: 'Rare'
    },
    {
        value: 'common',
        label: 'Common'
    },
];
export const categoriesMarket = [
    {
        value: 'rare',
        label: 'Rare'
    },
    {
        value: 'common',
        label: 'Common'
    },
    {
        value: 'high',
        label: 'Highest Price'
    },
    {
        value: 'low',
        label: 'Lowest Price'
    },
];
export const status = [
    {
        value: 'buy_now',
        label: 'Buy Now'
    },
    {
        value: 'on_auction',
        label: 'On Auction'
    },
    {
        value: 'has_offers',
        label: 'Has Offers'
    },
];

export const itemsType = [
    {
        value: 'single_items',
        label: 'Single Items'
    },
    {
        value: 'bundles',
        label: 'Bundles'
    }
];

export const collections = [
    {
        value: 'abstraction',
        label: 'Abstraction'
    },
    {
        value: 'patternlicious',
        label: 'Patternlicious'
    },
    {
        value: 'skecthify',
        label: 'Skecthify'
    },
    {
        value: 'cartoonism',
        label: 'Cartoonism'
    },
    {
        value: 'virtuland',
        label: 'Virtuland'
    },
    {
        value: 'papercut',
        label: 'Papercut'
    }
];