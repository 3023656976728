import React, { memo, useEffect, useState } from 'react';
import SSC from "@hive-engine/sscjs";
import NftMythiCard from './NftMythiCard';


const MyInventoryCards = (props) => {
    const [height, setHeight] = useState(0);
    const [cardsToShow, setCardsToShow] = useState(undefined);
    const [showCards, setShowCards] = useState(false);
    useEffect(() => {
        ShowCards();
    }, []);
    const ShowCards = async () => {
        props.setLoading(true);
        const ssc = new SSC('https://api.hive-engine.com/rpc');
        let cards = [];
        let readingCards = false;
        let cardsDefaseIndex = 0;
        while (!readingCards) {
            try {
                const nftList = await ssc.find('nft', 'MYTHICARDinstances', { account: sessionStorage.getItem('userName') }, 1000, cardsDefaseIndex, [], async (err, result) => {
                    if (err) {
                        return err;
                    }
                    if (result) {
                        return result;
                    }
                });
                //If has 1000 cards on this group, read again
                if (nftList.length >= 1000) {
                    cards.push(...nftList);
                    cardsDefaseIndex += 1000;
                } else {
                    cards.push(...nftList);
                    if(sessionStorage.getItem('invFilter') == 'rare'){
                        cards.sort(function (a, b) {
                            if (JSON.parse(a.properties.dbp).ri < JSON.parse(b.properties.dbp).ri) {
                                return 1;
                            }
                            if (JSON.parse(a.properties.dbp).ri > JSON.parse(b.properties.dbp).ri) {
                                return -1;
                            }
                            return 0;
                        });
                    }else if (sessionStorage.getItem('invFilter') == 'common'){
                        cards.sort(function (a, b) {
                            if (JSON.parse(a.properties.dbp).ri > JSON.parse(b.properties.dbp).ri) {
                                return 1;
                            }
                            if (JSON.parse(a.properties.dbp).ri < JSON.parse(b.properties.dbp).ri) {
                                return -1;
                            }
                            return 0;
                        });
                    }
                    setCardsToShow(cards);
                    props.setCardsDB(cards);
                    setShowCards(true);
                    readingCards = true;
                    setTimeout(afterLoad, 2000);
                }
            } catch (e) {
                alert(e)
            }
        }

    }
    const afterLoad = () => {
        props.setLoading(false);
    }
    const onImgLoad = ({ target: img }) => {
        let currentHeight = height;
        if (currentHeight < img.offsetHeight) {
            setHeight(img.offsetHeight);
        }
    }
    return (
        <div className='row'>
            {showCards && cardsToShow.slice(props.limitDown, props.limitUp).map((nft, index) => (
                <NftMythiCard nft={nft} key={index} onImgLoad={onImgLoad} height={height} 
                    setSelling={selling => props.setSelling(selling)}
                    setTransfer={transfer => props.setTransfer(transfer)}
                />
            ))}
        </div>
    );
};

export default memo(MyInventoryCards);