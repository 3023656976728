import React, { memo, useEffect, useState } from 'react';
import styled from "styled-components";
import { DataBaseCards } from '../../Utils/CardsDB';
import SSC from "@hive-engine/sscjs";

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

//react functional component
const NftMythiCardMarket = ({ nft, className = 'd-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4', height, onImgLoad, setPurchase, setLoading, setLoadingMsg }) => {
    const [img, setImg] = useState(undefined);
    const [name, setName] = useState('');
    const [faction, setFaction] = useState('');
    const [description, setDescription] = useState('');
    const [symbol, setSymbol] = useState('MYTHICARD')
    const [price, setPrice] = useState(0);
    const [priceSymbol, setPriceSymbol] = useState(0);
    const [seller, setSeller] = useState('');
    const hash = String(nft.grouping.ipfsHash);
    const newHas = hash.substring(1, hash.length - 1)
    let ipfsAddress = `https://${newHas}.ipfs.dweb.link`
    useEffect(() => {
        const gp = JSON.parse(nft.grouping.gp);
        const dbp = JSON.parse(nft.grouping.dbp);
        setName(gp.n);
        setFaction(gp.f);
        setDescription(gp.d);
        setPrice(nft.price);
        setPriceSymbol(nft.priceSymbol);
        setSeller(nft.account);
        for (let i = 0; i < DataBaseCards.length; i++) {
            if (DataBaseCards[i].dbid == dbp.dbid) {
                setImg(DataBaseCards[i].img);
                break;
            }
        }
    }, []);
    const onBuyBtn = () => {
        sessionStorage.setItem('itemSellerName', `${seller}`)
        sessionStorage.setItem('itemBuyID', `${nft.nftId}`)
        sessionStorage.setItem('itemBuySymbol', `${priceSymbol}`)
        sessionStorage.setItem('itemBuyPrice', `${price}`)
        sessionStorage.setItem('itemBuyName', `${name}`)
        sessionStorage.setItem('itemBuyNftSymbol', `${symbol}`)
        setPurchase(true);
    }
    const onCancelBtn = () => {
        setLoadingMsg('Cancelling Sell Order')
        setLoading(true);
        let keychain = window.hive_keychain;
        const userName = sessionStorage.getItem('userName');
        let my_json_obj = {
            "contractName": "nftmarket",
            "contractAction": "cancel",
            "contractPayload": {
                "symbol": `${symbol}`,
                "nfts": [`${nft.nftId}`]
            }
        }
        keychain.requestCustomJson(userName, "ssc-mainnet-hive", "Active", JSON.stringify(my_json_obj), "Cancel NFT sell order", (response) => {
            if (response.success === true) {
                checkTx(response.result.id);
            }
            else {
                alert('Something is wrong with cancell sell order');
                setLoading(false);
            }
        });
    }
    const checkTx = async (tx) => {
        setLoadingMsg(`Checking Cancel Sell TX ${tx}`);
        const ssc = new SSC('https://api.hive-engine.com/rpc');
        try {
            const transaction = await ssc.getTransactionInfo(tx, async (err, result) => {
                if (err) {
                    return err;
                }
                if (result) {
                    return result;
                }
            })
            if (transaction == null) {
                setTimeout(() => {
                    checkTx(tx);
                }, 3000);
            } else {
                let jsonLogs = JSON.parse(transaction.logs);
                if (jsonLogs.events == null) {
                    alert(`Something went wrong with cancel selling action \nerror: ${jsonLogs.errors[0]}`);
                }
                else {
                    setLoading(false);
                    window.location.reload();
                    alert('Cancel Sell Action Done');
                }
            }
        } catch (error) {
            return error.message;
        }
    }
    const goIpfs = () => {

    }
    return (
        <div className={className}>
            <div className="nft__item m-0">
                <div className="nft__item_wrap" style={{ height: `${height}px` }}>
                    <Outer>
                        <span>
                            <img onLoad={onImgLoad} src={img} className="lazy nft__item_preview" alt="" />
                        </span>
                    </Outer>
                </div>
                <div className="nft__item_info2">
                    <h6 className='des-price'>Price: {parseFloat(price).toFixed(3)} {priceSymbol}</h6>
                    <h6 className='des-seller'>Seller: {seller}</h6>
                    <a target="_blank" rel="noopener noreferrer" href={ipfsAddress}>
                        <h4>{name}</h4>
                    </a>
                    <h4 className='type'>{faction} <span className="bold">(nft ID: {nft.nftId})</span></h4>
                    <div className="nft__description">
                        {description}
                    </div>
                    {seller != sessionStorage.getItem('userName') && <button className='btn-nftCard'
                        onClick={() => { onBuyBtn() }}
                    >Buy</button>}
                    {seller == sessionStorage.getItem('userName') && <button className='btn-nftCard'
                        onClick={() => { onCancelBtn() }}
                    >Cancel Sell</button>}
                </div>{/**/}
            </div>
        </div>
    );
};

export default memo(NftMythiCardMarket);